import React from 'react'
import { Link } from 'gatsby'

// Components
import CatSpread from './CatSpread'
import PostDate from './PostDate'
import TimeRead from './TimeRead'

const PreviewPost = ({ post }) => {
  const fm = post.frontmatter
  const cats = post.frontmatter.category

  return (
    <>
      <div className="post-preview">
        <Link to={`/${fm.slug}`}>
          <h2 className="post-title">{fm.title ? fm.title : 'empty'}</h2>
          {fm.description && <h3 className="post-subtitle">{fm.description}</h3>}
        </Link>
        <p className="post-meta">
          Posted in <CatSpread cats={cats} /> on <PostDate date={fm.date} />
          <TimeRead time={post.timeToRead} />
        </p>
      </div>
      <hr />
    </>
  )
}

export default PreviewPost
