import titleCase from './titleCase'

const nameFormat = tag => {
  const t = lowercase(tag).toString()

  switch (t) {
    case 'jpg':
      return 'JPG'
    case 'tif':
      return 'TIF'
    case 'posix':
      return 'POSIX'
    default:
      return titleCase(t)
  }
}

const lowercase = tag => tag.toString().toLowerCase()

const convertor = (tag, title = false) => (title === true ? nameFormat(tag) : lowercase(tag))

export default convertor
