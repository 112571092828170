import React from 'react'
import { graphql } from 'gatsby'

// Components
import Seo from '../components/Seo'
import Layout from '../components/Layout'
import PreviewPost from '../components/PreviewPost'

// utils
import tagResolve from '../../utils/tagResolve'

const Tag = props => {
  const {
    pageContext: { tag },
  } = props

  const {
    data: {
      tags: { nodes: posts },
    },
  } = props

  return (
    <>
      <Layout>
        <Seo
          title={tagResolve(tag, true)}
          url={props.location.href}
          keywords={[`${tagResolve(tag, true)}`, 'blog', 'code']}
        />
        <header className="masthead">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <div className="site-heading">
                  <h1>{tagResolve(tag, true)}</h1>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">
              {posts.map(post => {
                return <PreviewPost key={post.id} post={post} />
              })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query GetTags($tag: String) {
    tags: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { tags: { eq: $tag } } }
    ) {
      nodes {
        id
        frontmatter {
          title
          category
          description
          date
          slug
        }
        timeToRead
      }
      totalCount
    }
  }
`

export default Tag
